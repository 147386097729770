import React, {useEffect, useState} from 'react';
import '../index.css';
import {Dialog} from '@headlessui/react';
import {ArrowLeftIcon, ArrowRightIcon, BackwardIcon, Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {
    AcademicCapIcon,
    BanknotesIcon,
    CheckBadgeIcon,
    ClockIcon,
    ReceiptRefundIcon,
    UsersIcon,
} from '@heroicons/react/24/outline'

const navigation = [
    {name: 'Top KINGY buyers', href: 'kingy-buy'},
    {name: 'Top ARBUZ buyers', href: 'arbuz-buy'},
    {name: 'Top KINGY traders', href: 'kingy-trade'},
    {name: 'Top ARBUZ traders', href: 'arbuz-trade'},
    {name: 'Top MAMBA traders', href: 'mamba-trade'},
    {name: 'Top MAMBA traders', href: 'mamba-trade'},
];

const actions = [
    {
        title: 'Top KINGY buyers',
        href: 'kingy-buy',
        icon: ClockIcon,
        imageUri: "https://kingy.wtf/logoCMC.png",
        iconForeground: 'text-teal-700',
        iconBackground: 'bg-teal-50',
    },
    {
        title: "Top KINGY traders",
        href: 'kingy-trade',
        icon: UsersIcon,
        imageUri: "https://kingy.wtf/logoCMC.png",
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
    },
    {
        title: 'Top ARBUZ buyers',
        href: 'arbuz-buy',
        icon: CheckBadgeIcon,
        imageUri: "https://kingy.wtf/arb-logo-CMC.png",
        iconForeground: 'text-purple-700',
        iconBackground: 'bg-purple-50',
    },
    {
        title: "Top ARBUZ traders",
        href: 'arbuz-trade',
        icon: BanknotesIcon,
        imageUri: "https://kingy.wtf/arb-logo-CMC.png",
        iconForeground: 'text-yellow-700',
        iconBackground: 'bg-yellow-50',
    },
    {
        title: "Top MUMBA buyers",
        href: 'mamba-buy',
        icon: ReceiptRefundIcon,
        imageUri: "https://i.imgur.com/rQoNGn4.png",
        iconForeground: 'text-rose-700',
        iconBackground: 'bg-rose-50',
    },
    {
        title: "Top MUMBA traders",
        href: 'mamba-trade',
        icon: AcademicCapIcon,
        imageUri: "https://i.imgur.com/rQoNGn4.png",
        iconForeground: 'text-indigo-700',
        iconBackground: 'bg-indigo-50',
    },
]

const rewards = [
    300,200,150,50,50,50,50,50,50,50
]

const rewards2 = [
    600,500,400,70,70,70,70,70,70,70
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Home = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [table, setTable] = useState("");
    const [rating, setRating] = useState([]);
    const [loading, setLoading] = useState(true);

    const convertAmount = (amount) => {
        // 1k, 1m, 1b, 1t with float
        if (amount >= 1e12) return (amount / 1e12).toFixed(1) + 't';
        if (amount >= 1e9) return (amount / 1e9).toFixed(1) + 'b';
        if (amount >= 1e6) return (amount / 1e6).toFixed(1) + 'm';
        if (amount >= 1e3) return (amount / 1e3).toFixed(1) + 'k';
        return amount.toFixed(1);
    };

    const convertAddress = (address) => {
        // format the address as desired
        return address.slice(0, 6) + ' • • • ' + address.slice(-4);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch("https://api.dedust.io/v2/jettons/EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE/top-buys");
                const data = await response.json();
                setRating(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    async function fetchKingyBuy() {
        try {
            const response = await fetch("https://api.dedust.io/v2/jettons/EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ/top-buys");
            const data = await response.json();
            setRating(data);
        } catch (error) {
            console.error('Error fetching Kingy Buy data:', error);
        }
    }

    async function fetchArbuzBuy() {
        try {
            const response = await fetch("https://api.dedust.io/v2/jettons/EQAM2KWDp9lN0YvxvfSbI0ryjBXwM70rakpNIHbuETatRWA1/top-buys");
            const data = await response.json();
            setRating(data);
        } catch (error) {
            console.error('Error fetching Arbuz Buy data:', error);
        }
    }

    async function fetchKingyTrade() {
        try {
            const response = await fetch("https://api.dedust.io/v2/jettons/EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ/top-traders");
            const data = await response.json();
            setRating(data);
        } catch (error) {
            console.error('Error fetching Kingy Buy data:', error);
        }
    }

    async function fetchArbuzTrade() {
        try {
            const response = await fetch("https://api.dedust.io/v2/jettons/EQAM2KWDp9lN0YvxvfSbI0ryjBXwM70rakpNIHbuETatRWA1/top-traders");
            const data = await response.json();
            setRating(data);
        } catch (error) {
            console.error('Error fetching Arbuz Buy data:', error);
        }
    }

    async function fetchMambaBuys() {
        try {
            const response = await fetch("https://api.dedust.io/v2/jettons/EQCXIHAWDAJEJhMiNVqeUvp3I6O7y_P2sD80zEsJXeUi56mb/top-buys");
            const data = await response.json();
            setRating(data);
        } catch (error) {
            console.error('Error fetching Kingy Buy data:', error);
        }
    }

    async function fetchMambaTrade() {
        try {
            const response = await fetch("https://api.dedust.io/v2/jettons/EQCXIHAWDAJEJhMiNVqeUvp3I6O7y_P2sD80zEsJXeUi56mb/top-traders");
            const data = await response.json();
            setRating(data);
        } catch (error) {
            console.error('Error fetching Arbuz Buy data:', error);
        }
    }

    useEffect(() => {
        if (loading) return;
        setRating([]);
        if (table === "kingy-buy") fetchKingyBuy();
        if (table === "arbuz-buy") fetchArbuzBuy();
        if (table === "kingy-trade") fetchKingyTrade();
        if (table === "arbuz-trade") fetchArbuzTrade();
        if (table === "mamba-trade") fetchMambaTrade();
        if (table === "mamba-buy") fetchMambaBuys();
    }, [table]);

    return (
        <div className={"app"}>
            <header className="bg-white">
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Your Company</span>
                        <img className="h-8 w-auto" onClick={() => setTable("")}
                             src={table.includes("kingy") || table == "" ? "https://kingy.wtf/logoCMC.png" : table.includes("arbuz") ? "https://kingy.wtf/arb-logo-CMC.png" : "https://i.imgur.com/rQoNGn4.png"}
                             alt=""/>
                    </a>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10"/>
                    <Dialog.Panel
                        className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    className="h-8 w-auto"
                                    onClick={() => setTable("")}
                                    src={table.includes("kingy")  || table == "" ? "https://kingy.wtf/logoCMC.png" : table.includes("arbuz") ? "https://kingy.wtf/arb-logo-CMC.png" : "https://i.imgur.com/rQoNGn4.png"}
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
            <div className="px-4 sm:px-6 lg:px-8">
                {!table && (
                    <>
                    <div className="md:flex md:items-center md:justify-between mt-8 mb-4">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                Rating
                            </h2>
                        </div>
                    </div>
                    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                        {actions.map((action, actionIdx) => (
                            <div
                                key={action.title}
                                className={classNames(
                                    actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                                    actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                    actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                                    actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                                    'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                                )}
                            >
                                <div>
            <span
                className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'inline-flex rounded-lg p-3 ring-4 ring-white'
                )}
            >
                <img src={action.imageUri} className="h-6 w-6" aria-hidden="true"/>
            </span>
                                </div>
                                <div className="mt-8">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                                        <a onClick={() => {
                                            setTable(action.href)
                                        }} className="focus:outline-none">
                                            <span className="absolute inset-0" aria-hidden="true" />
                                            {action.title}
                                        </a>
                                    </h3>
                                </div>
                                <span
                                    className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                                    aria-hidden="true"
                                >
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
                            </div>
                        ))}
                    </div>
                    </>
                )}
                {table && (
                    <>
                <div className="md:flex md:items-center md:justify-between">
                    <div className="min-w-0 flex-1 flex items-center gap-3">
                        <ArrowLeftIcon className="h-6 w-6 text-gray-500 cursor-pointer" onClick={() => setTable("")}/>
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            {table === "kingy-buy" ? "Top KINGY buyers" : table === "arbuz-buy" ? "Top ARBUZ buyers" : table === "kingy-trade" ? "Top KINGY traders" : table === "arbuz-trade" ? "Top ARBUZ traders" : table === "mamba-trade" ? "Top MUMBA traders" : "Top MUMBA buyers"}
                        </h2>
                    </div>
                    <div className="mt-4 flex md:ml-4 md:mt-0">
                        <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => {
                                setRating([]);
                                if (table === "kingy-buy") fetchKingyBuy();
                                if (table === "arbuz-buy") fetchArbuzBuy();
                                if (table === "kingy-trade") fetchKingyTrade();
                                if (table === "arbuz-trade") fetchArbuzTrade();
                                if (table === "mamba-trade") fetchMambaTrade();
                                if (table === "mamba-buy") fetchMambaBuys();
                            }}
                        >
                            Refresh
                        </button>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            #
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Address
                                        </th>
                                        {table.includes("buy") ? (
                                            <th scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Amount
                                            </th>
                                        ) : (
                                            <>
                                                <th scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Volume
                                                </th>
                                                <th scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Swaps
                                                </th>
                                            </>
                                        )}
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Reward {table.includes("kingy") || table.includes("arbuz") ? "(DFC)" : "($)"}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {rating.length === 0 && (
                                        <tr>
                                            <td colSpan={3}
                                                className="text-center py-4 text-sm text-gray-500">Loading...
                                            </td>
                                        </tr>
                                    )}
                                    {rating.map((user, i) => (
                                        <tr key={user.walletAddress}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {Number(i) + 1}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer hover:text-black"
                                                onClick={() => {
                                                    window.open(`https://tonviewer.com/${user.walletAddress}`, '_blank')
                                                }}>{convertAddress(user.walletAddress)}                 </td>
                                            {table.includes("buy") ? (
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{convertAmount(Number(user.amount) / Math.pow(10, 9))}</td>
                                            ) : (
                                                <>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{convertAmount(Number(user.volume) / Math.pow(10, 9))}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.swaps}</td>

                                                </>
                                            )}
                                            {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{table.includes("mamba") ? rewards2[i] : rewards[i]}                 </td>*/}

                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Home;